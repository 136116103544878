import * as React from "react"
import { makeStyles } from '@material-ui/core/styles';
import MainLayout from '../../layouts/main-layout';
import { WebsiteHeading, LandingPageImage } from '../../components';
import Button from '@material-ui/core/Button';
import EmailSignUpForm from './email-signup-form';
import ConnectivityImage from '../../images/connectivity.png';

const useStyles = makeStyles((theme) => ( {
    pageBackground: {
        background: '#F6F8FC',
        minHeight: '100%',
    },
    page: {
        padding: '40px',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
        position: 'relative',
    },
    optionsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        margin: 0,
        padding: 0,
    },
    pageImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    optionBoxPositioner: {
        width: '50%',
        boxSizing: 'border-box',
        padding: '1rem 1rem 0rem 0rem',
        margin: 0,
        
        '&:hover $optionBox': {
            background: '#0FC1B7',
        }
    },
    optionBox: {
        color: '#F6F8FC',
        cursor: 'pointer',
        background: '#131f37',
        padding: '2rem 0rem',
        border: '0px solid #131f37',
        borderRadius: '5px',
        transition: 'background-color 500ms linear',
        width: '100%',
        boxSizing: 'border-box',
        textTransform: 'capitalize',
        textAlign: 'center',
        fontSize: 'unset',
        lineHeight: 'unset',

        [theme.breakpoints.down(480)]: {
            textAlign: 'center',
        },

        '& .MuiButton-label': {
            justifyContent: 'start',
            fontSize: 'unset',
            alignItems: 'start',
            lineHeight: 'unset',
            textAlign: 'center',
            display: 'block',
            
            [theme.breakpoints.down(480)]: {
                textAlign: 'center',
                display: 'block',
            }
        }
    },
    optionLink: {
        textDecoration: 'none',
    },
    pageSection: {
        marginBottom: '2rem',
    },

    pageTextBlock: {
        [theme.breakpoints.down(480)]: {
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },

    shopLink: {
        [theme.breakpoints.down(480)]: {
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    }
    
} ));

const SupportUsPage = () => {
    const classes = useStyles();

    const PageImage = (
        <img alt="Connectivity abstract image" className={classes.pageImage} src={ConnectivityImage} />
      );

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <MainLayout>
        <main className={classes.pageBackground}>
            <title>Support Us</title>
            <LandingPageImage image={PageImage} text={[{ type: 'basic', text: 'Supporting Innovation In Sustainability' }]} />     

            <div className={classes.page}>
                <div className={classes.pageDescription}>
                    <section className={classes.pageSection}>
                        <WebsiteHeading isPageHeader text={'Join Our Community'}/>
                        <p className={classes.pageTextBlock}>
                            Have we peaked your interest? If you don't want to miss out on our latest news, opportunities to 
                            get involved and special offers then sign up to our email list and join us on social media. Click 
                            the boxes below.
                        </p>
                        <ul className={classes.optionsContainer}>
                        <li className={classes.optionBoxPositioner}>
                            <div className={classes.optionLink}>
                                <Button className={classes.optionBox} onClick={handleClickOpen}>Email</Button>
                            </div>
                            <EmailSignUpForm open={open} onClose={handleClose} />
                        </li>
                        <li className={classes.optionBoxPositioner}>
                            <a className={classes.optionLink} href="https://www.facebook.com/TecorraShop" target="_blank" rel="noreferrer">
                                <div className={classes.optionBox}>Facebook</div>
                            </a>
                        </li>
                        <li className={classes.optionBoxPositioner}>
                            <a className={classes.optionLink} href="http://twitter.com/tecorrashop" target="_blank" rel="noreferrer">
                                <div className={classes.optionBox}>Twitter</div>
                            </a>
                        </li>
                        <li className={classes.optionBoxPositioner}>
                            <a className={classes.optionLink} href="https://www.instagram.com/tecorrashop/" target="_blank" rel="noreferrer">
                                <div className={classes.optionBox}>Instagram</div>
                            </a>
                        </li>
                    </ul>
                    </section>
                    <section className={classes.pageSection}>
                        <WebsiteHeading isPageHeader text={'Get Involved'}/>
                        <p className={classes.pageTextBlock}>
                            We need one thing more than anything to succeed: people. We need brands, manufacturers, suppliers and 
                            retailers saying yes to far more sustainable business models. We need consumers demanding more 
                            sustainable products. We need influencers, press and bloggers writing about us to be heard and 
                            noticed by the masses. 
                        </p>
                        <p className={classes.pageTextBlock}>
                            So if you want to help, the biggest thing we need right now is a voice. If you're a consumer, 
                            mention us to your local shop owners, your favourite brands and your friends. If you're a business owner
                            sign up to our private research group and mention us on social media. If you're a blogger, influencer or 
                            the press, get in touch and write about us. 
                        </p>
                        <p className={classes.pageTextBlock}>
                            Change is on the horizon. Will you be part of it?
                        </p>
                        {/* <div>
                            <form className={classes.signUpForm} onSubmit={handleSubmit}>
                                <div className={classes.formRow}>
                                    <label htmlFor="name">Name:</label>
                                    <input className={classes.formField} type='text' name='name'  />
                                </div>
                                <div className={classes.formRow}>
                                <label htmlFor="type">I am a:</label>
                                    <select name="type" className={classes.formField}>
                                        <option>Brand Representative</option>
                                        <option>Retail Representative</option>
                                        <option>Manufacturer Representative</option>
                                        <option>Supplier Representative</option>
                                        <option>Consumer</option>
                                        <option>Verification Company Representative</option>
                                    </select>
                                </div>
                                <div className={classes.formRow}>
                                    <label htmlFor="email">Email:</label>
                                    <input className={classes.formField} type='email' name='email' />
                                </div>     
                                <div className={classes.formRow}>
                                    <div className={classes.formSubmitContainer}>
                                        <button type='submit' className={classes.formSubmitButton}>Send</button>
                                    </div>    
                                </div>  
                            </form>
                        </div> */}
                    </section>
                    <section className={classes.pageSection}>
                        <WebsiteHeading isPageHeader text={'Financial Support'}/>
                        <p className={classes.pageTextBlock}>
                            Tecorra is our online shop and it's a great
                            way to show your support with every purchase helping to financially support our development - like
                            hosting our servers or purchasing IOT devices. We will 
                            also be releasing further ways to help and new products in the future, so if you haven't already 
                            joined us on social media or signed up to our email list do it now to keep up with our latest news.
                        </p>
                        <div className={`${classes.optionsContainer} ${classes.pageTextBlock}`}>
                            <div>
                                <a className={classes.shopLink} href="https://tecorra.co.uk" target="_blank" rel="noreferrer">Click here to visit our shop.</a>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
        </MainLayout>
    );
}

export default SupportUsPage;